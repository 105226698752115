import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchaseOrderService {
    readPurchaseOrderQuery(){
        var query = `query($paging:ServerPaging, $status:String){
            GetTransactionPurchaseOrder(Paging:$paging, Status:$status){
                    transaction_purchase_order{
                        purchase_order_id
                        status
                        purchase_order_number
                        purchase_order_date
                        purchase_order_type
                        created_at
                        sp_number
                        customer_name
                        total
                        project_type
                        project_name
                        payment_method
                        notes
                        attachment
                        created_by
                        sales_name
                        approved_by
                        project {
                            forecast_type
                        }
                        purchase_order_detail{
                            item_id
                        }
                        booking_order{
                            item_id
                        }
                        term_of_payment
                        booking_purchase_order_id
                    }
                    total
                }
            }`;
        return query;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getContactDetailQuery(variables){
        var query = gql`query($type:String,$id:Int){
            GetContact(ContactType:$type, ContactId:$id){
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                on_going_purchase_order_total_by_customer_id
            }
        }`;
        if (variables.id == '' || variables.id == null) {
            return null;
        }
        else {
            var result = await globalfunc.defaultApolloQueryDefault(query,variables);
            return result.data.GetContact[0];
        }
    }  

    async getPurchaseOrderQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetTransactionPurchaseOrder(PurchaseOrderID:$id){
                transaction_purchase_order {
                    created_at
                    last_update
                    purchase_order_id
                    purchase_order_number
                    sp_number
                    purchase_order_date
                    status
                    project_id
                    project {
                        created_at
                        last_update
                        project_id
                        project_code
                        project_type
                        project_date
                        project_duration
                        project_name
                        status
                        pre_project_id
                        pre_project_code
                        pre_project_name
                        forecast_type
                        storage_id
                        storage_name
                        price_category_id
                        price_category_name
                        areacode
                        areaname
                        sales_id
                        sales_name
                        city
                        attachment
                        notes
                        project_detail
                    }
                    project_name
                    project_type
                    payment_method
                    sales_id
                    sales {
                        created_at
                        last_update
                        contact_id
                        contact_code
                        contact_type
                        contact_name
                        status
                        metadata
                        attachment1
                        attachment2
                    }
                    sales_name
                    customer_id
                    customer {
                        created_at
                        last_update
                        contact_id
                        contact_code
                        contact_type
                        contact_name
                        status
                        metadata
                        attachment1
                        attachment2
                    }
                    customer_name
                    storage_id
                    price_category_id
                    purchase_order_sequence
                    purchase_order_type
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    sample
                    total
                    tax_percentage
                    invoice_number
                    delivery_order_number
                    created_by
                    approved_by
                    payment_status
                    booking_purchase_order_id
                    purchase_order_detail {
                        created_at
                        last_update
                        purchase_order_id
                        item_id
                        product_code
                        barcode
                        type_detail_id
                        item_name
                        uom
                        base_uom
                        conv_amount
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                        quantity
                        quantity_base
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        tax_amount
                    }
                    booking_order {
                        created_at
                        last_update
                        booking_order_id
                        purchase_order_id
                        item_id
                        product_code
                        barcode
                        type_detail_id
                        item_name
                        uom
                        base_uom
                        conv_amount
                        price_per_unit
                        price
                        vat_per_unit
                        vat
                        quantity
                        quantity_base
                        quantity_production
                        quantity_ready
                        quantity_current
                        quantity_purchase_order
                        stock_sales
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        discount1_amount
                        discount2_amount
                        discount3_amount
                        discount4_amount
                        discount5_amount
                        discount_cash_amount
                        tax_amount
                        customer_id
                        customer_name
                        purchase_order_number
                        storage_id
                    }
                    term_of_payment
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionPurchaseOrder.transaction_purchase_order[0];

    }

    async getProjectDropdownByCustomerId(customerId, projectType){
        var projectTypeIndex = 0;
        if(projectType == "Project"){
            projectTypeIndex = 1
        }
        if(projectType == "Retail"){
            projectTypeIndex = 2
        }
        const variables = {
            contactId : customerId,
            projectType : projectTypeIndex,
            status : "Ready"
        }
        
        var query = gql`query($contactId:Int, $projectType:Int, $status:[String]){
            GetTransactionProject(ContactID:$contactId, ProjectType:$projectType, Status:$status){
                project{
                    project_id
                    project_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        if(result.data.GetTransactionProject == null){
            return [];
        }else{
            return globalfunc.objectToArrayConverter(result.data.GetTransactionProject.project, 'Transaction-PO-Project');
        }
    }

    async getProjectDetail(id){
        const variables = {
            id : id
        }
        
        var query = gql`query($id:Int){
            GetTransactionProject(ProjectId:$id){
                project{
                    project_id
                    project_code
                    project_name
                    forecast_type
                    sales_id
                    sales_name
                    price_category_id
                    storage_id
                    project_detail
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetTransactionProject.project[0];
    }

    poDataDropDown(totalPO){
        var poData = [];

        if(totalPO < 1){
            poData = [{value: 0}];
        }else{
            for (let i = 1; i <= totalPO; i++) {
                var str = {value: i}
                poData.push(str);
            }
        }

        return poData;
    }

    async readTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]) {
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }
    
    readItemQuery(){
        var query = `query($id: Int!, $po: Int, $typeDetailId: String){
            GetTransactionPurchaseOrderableItemList(ProjectID: $id, PurchaseOrderSequence: $po, TypeDetailId: $typeDetailId){
                project_id
                purchase_order_sequence
                storage_id
                storage_name
                price_category_id
                price_category_name
                item_id
                product_code
                item_name
                barcode
                item_type
                type_detail_id
                type_detail_name
                base_uom
                uom
                conv_amount
                base_stock
                stock
                forcast_quantity
                ordered_quantity
                remaining_quantity
                quantity
                base_weight
                discount1
                discount2
                discount3
                discount4
                discount5
                discount_cash
                discount_amount_1
                discount_amount_2
                discount_amount_3
                discount_amount_4
                discount_amount_5
                discount_amount_cash
                price_retail
                price_project
                base_price_retail
                base_price_project
                price_retail_discount
                price_project_discount
                base_price_retail_discount
                base_price_project_discount
                price_retail_discount_cash
                price_project_discount_cash
                base_price_retail_discount_cash
                base_price_project_discount_cash
            }
        }`;
        return query;
    }

    columnsProject(type, forecast_type){
        var price = "";
        var quantity = "";
        var columns = [];

        if(type == 'Project'){
            price = 'price_project';
        }else if(type == 'Retail'){
            price = 'price_retail';
        }

        if(forecast_type == 'Forecast 1' || forecast_type == 'Paket'){
            quantity = 'forcast_quantity';
        }else {
            quantity = 'selected_qty';
        }

        if(forecast_type == 'Forecast 2'){
            columns = [
                { field: "type_detail_name", title: "Tipe Detail", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "conv_amount", title: "Nilai Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: price, title: "Harga", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                { field: "remaining_quantity", title: "Limit Quantity PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: quantity, title: "Quantity", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format: "{0:0}" },
                { field: "discount1", title: "Diskon 1 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount_cash", title: "Diskon Cash (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ];
        }else{
            columns = [
                { field: "type_detail_name", title: "Tipe Detail", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "conv_amount", title: "Nilai Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: price, title: "Harga", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                { field: quantity, title: "Quantity", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format: "{0:0}" },
                { field: "discount1", title: "Diskon 1 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount_cash", title: "Diskon Cash (%)", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ];
        } 

        return columns;
    }

    fillSelectedQuantity(data, forecast_type){
        if(forecast_type == 'Forecast 1' || forecast_type == 'Paket'){
            //do nothing
        }else{
            data.forEach(function(data, key) {
                if(forecast_type == 'None' || forecast_type == 'Total'){
                    //do nothing
                }else{
                    data.selected_qty = data.remaining_quantity;
                }
            });
        }
        
        return data;
    }

    itemDataObject(data, qty, type){
        var price = 0;
        var net = 0;

        if(type == 'Project'){
            price = data.price_project;
            net = data.price_project_discount_cash;
        }else if(type == 'Retail'){
            price = data.price_retail;
            net = data.price_retail_discount_cash;
        }

        var price_discount = data.discount_amount_1 + data.discount_amount_2 + data.discount_amount_3 + data.discount_amount_4 + data.discount_amount_5 + data.discount_amount_cash;
        var real_qty = data.conv_amount * qty;
        var total_price = qty * net;

        var dataItem = [{
            item_id : data.item_id,
            product_code : data.product_code,
            item_name : data.item_name,
            price : price,
            price_discount : price_discount,
            net : net,
            qty : qty,
            current_qty : qty,
            real_qty : real_qty,
            total_price : total_price,
            discount1 : data.discount1,
            discount2 : data.discount2,
            discount3 : data.discount3,
            discount4 : data.discount4,
            discount5 : data.discount5,
            discount_cash : data.discount_cash,
            discount_amount_1 : data.discount_amount_1,
            discount_amount_2 : data.discount_amount_2,
            discount_amount_3 : data.discount_amount_3,
            discount_amount_4 : data.discount_amount_4,
            discount_amount_5 : data.discount_amount_5,
            discount_amount_cash : data.discount_amount_cash,
            stock : data.stock,
            conv_amount : data.conv_amount,
            uom : data.uom,
            type_detail_id: data.type_detail_id
        }];
        
        return dataItem;
    }

    duplicateItemProjectChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].item_id == newData.item_id){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    itemGridChange(selectedItem, itemData, index, itemBookingData, indexBooking){
        //change item
        var item = itemData;

        if(selectedItem.qty > selectedItem.stock){
            var real_qty = selectedItem.conv_amount * selectedItem.stock;
            var total_price = selectedItem.stock * selectedItem.net;

            item[index].qty = selectedItem.stock;
            item[index].real_qty = real_qty;
            item[index].total_price = total_price;
        }else{
            var real_qty = selectedItem.conv_amount * selectedItem.qty;
            var total_price = selectedItem.qty * selectedItem.net;

            item[index].qty = selectedItem.qty;
            item[index].real_qty = real_qty;
            item[index].total_price = total_price;
        }

        return item;
    }

    itemBookingGridChange(selectedItem, itemData, index, itemBookingData, indexBooking){
        //change booking item
        var itemBooking = itemBookingData;

        if(indexBooking == -1){
            if(selectedItem.qty > selectedItem.stock){
                var qty_booking = selectedItem.qty - selectedItem.stock;
                var real_qty_booking = selectedItem.conv_amount * qty_booking;
                var total_price_booking = qty_booking * selectedItem.net;

                if(indexBooking < 0){
                    var itemBookingNew = [{
                            item_id : selectedItem.item_id,
                            product_code : selectedItem.product_code,
                            item_name : selectedItem.item_name,
                            price : selectedItem.price,
                            price_discount : selectedItem.price_discount,
                            net : selectedItem.net,
                            current_qty : qty_booking,
                            qty : qty_booking,
                            real_qty : real_qty_booking,
                            total_price : total_price_booking,
                            discount1 : selectedItem.discount1,
                            discount2 : selectedItem.discount2,
                            discount3 : selectedItem.discount3,
                            discount4 : selectedItem.discount4,
                            discount5 : selectedItem.discount5,
                            discount_cash : selectedItem.discount_cash,
                            discount_amount_1 : selectedItem.discount_amount_1,
                            discount_amount_2 : selectedItem.discount_amount_2,
                            discount_amount_3 : selectedItem.discount_amount_3,
                            discount_amount_4 : selectedItem.discount_amount_4,
                            discount_amount_5 : selectedItem.discount_amount_5,
                            discount_amount_cash : selectedItem.discount_amount_cash,
                            stock : selectedItem.stock,
                            conv_amount : selectedItem.conv_amount,
                            uom : selectedItem.uom,
                        }];
                    itemBooking = itemBooking.concat(itemBookingNew);
                }
            }
        }else if(indexBooking >= 0){
            if(selectedItem.qty < selectedItem.stock){
                itemBooking.splice(indexBooking, 1)
            }else{
                var qty_booking = selectedItem.qty - selectedItem.stock;
                var real_qty_booking = selectedItem.conv_amount * qty_booking;
                var total_price_booking = qty_booking * selectedItem.net;

                itemBooking[indexBooking].qty = qty_booking;
                itemBooking[indexBooking].real_qty = real_qty_booking;
                itemBooking[indexBooking].total_price = total_price_booking;
            }
        }
        
        return itemBooking;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewTransactionPurchaseOrder!){
            CreateTransactionPurchaseOrder(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewTransactionPurchaseOrder!){
            UpdateTransactionPurchaseOrder(PurchaseOrderId:$id, New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    duplicateAddressChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i] == newData){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    async editDistributor(variables){
        var query = gql`mutation($id:Int!,$data:NewContact!){
            UpdateContact(contactID:$id,contact:$data){
                response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    editItemGridDataGenerator(data){
        var result = [];
        
        if(data != null){
            for (let i = 0; i < data.length; i++){
                var price_discount = data[i].discount1_amount + data[i].discount2_amount + data[i].discount3_amount + data[i].discount4_amount + data[i].discount5_amount + data[i].discount_cash_amount;
                var real_qty = data[i].conv_amount * data[i].quantity;
                var net = data[i].price_per_unit - price_discount;
                var total_price = data[i].quantity * net;

                var str = {
                    item_id : data[i].item_id,
                    product_code : data[i].product_code,
                    item_name : data[i].item_name,
                    price : data[i].price_per_unit,
                    price_discount : price_discount,
                    net : net,
                    qty : data[i].quantity,
                    current_qty : data[i].quantity_current,
                    real_qty : real_qty,
                    total_price : total_price,
                    discount1 : data[i].discount1,
                    discount2 : data[i].discount2,
                    discount3 : data[i].discount3,
                    discount4 : data[i].discount4,
                    discount5 : data[i].discount5,
                    discount_cash : data[i].discount_cash,
                    discount_amount_1 : data[i].discount1_amount,
                    discount_amount_2 : data[i].discount2_amount,
                    discount_amount_3 : data[i].discount3_amount,
                    discount_amount_4 : data[i].discount4_amount,
                    discount_amount_5 : data[i].discount5_amount,
                    discount_amount_cash : data[i].discount_cash_amount,
                    stock : 0,
                    conv_amount : data[i].conv_amount,
                    uom : data[i].uom,
                    type_detail_id: data[i].type_detail_id
                };
                if(data[i].quantity > 0){
                    result.push(str)
                }
            }
        }
        
        return result;
    }
    
    async getLimitRemaining(id, projectType){
        if(projectType == 'None'){
            var variables = {id : id};
            var query = gql`query($id:Int){
                GetTransactionPurchaseOrder(ProjectID:$id){
                    transaction_purchase_order{
                        on_going_purchase_order_total_by_project_id
                    }
                }
            }`;
            var result = await globalfunc.defaultApolloQueryDefault(query, variables);
            var data = result.data.GetTransactionPurchaseOrder.transaction_purchase_order;
            
            var total = 0;
            if(data != null){
                if(data.length > 0){
                    total = data[0].on_going_purchase_order_total_by_project_id;
                }
            }

            return total;
        }else{
            return 0;
        }
    }

    async purchaseOrderDetailTemplate(data){
        var itemDetail = this.editItemGridDataGenerator(data.purchase_order_detail);
        var itemBookingDetail = this.editItemGridDataGenerator(data.booking_order);
        var totalGross = 0;
        var totalDisc = 0;
        var totalPPNTax = 0;
        var totalGrossDisc = 0;
        var grandTotal = 0;

        if(itemDetail.length > 0){
            itemDetail.forEach(e => {
                totalGross = totalGross + (e.price * e.qty);
                totalDisc = totalDisc + (e.price_discount * e.qty);
                totalGrossDisc = totalGrossDisc + (e.net * e.qty);
                totalPPNTax = totalPPNTax + (e.net * (data.tax_percentage / 100)) * e.qty;
            });
        }
    
        if(itemBookingDetail.length > 0){
            itemBookingDetail.forEach(e => {
                totalGross = totalGross + (e.price * e.qty);
                totalDisc = totalDisc + (e.price_discount * e.qty);
                totalGrossDisc = totalGrossDisc + (e.net * e.qty);
                totalPPNTax = totalPPNTax + (e.net * (data.tax_percentage / 100)) * e.qty;
            });
        }

        grandTotal = totalGrossDisc + totalPPNTax;
        var doNumber = data.delivery_order_number == null ? "" : data.delivery_order_number;
        var invoiceNumber = data.invoice_number == null ? "" : data.invoice_number;

        var html = `
        <div class="row p-1" width="100%">
            <div class="col-3 px-2">
                <label class="form-label"><b>PO Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. PO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.purchase_order_number +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. SP</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.sp_number +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Tgl. PO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ moment(data.purchase_order_date).format('DD MMMM YYYY') +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Cara Bayar</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.payment_method +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Jatuh Tempo</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.term_of_payment +` hari</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Note</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.notes +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. DO</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ doNumber +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. Invoice</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ invoiceNumber +`</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 px-2">
                <label class="form-label"><b>Customer Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Nama</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.contact_name +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. Telepon</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.metadata.profile.phone1 +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">No. HandPhone</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.metadata.profile.handphone +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Fax</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.metadata.profile.fax +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Email</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.metadata.profile.email +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Alamat</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.customer.metadata.profile.address +`</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 px-2">
                <label class="form-label"><b>Project Info</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Kode</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.project.project_code +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Nama</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">`+ data.project.project_name +`</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 px-2">
                <label class="form-label"><b>Total</b></label>
                <div class="p-3" width="100%" style="border-style:solid; border-radius: 3px; border-width: thin; border-color:lightgrey">
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Total Gross</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Rp. `+ kendo.toString(totalGross, "n0") +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Total Disc</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Rp. `+ kendo.toString(totalDisc, "n0") +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">PPN `+ data.tax_percentage +`%</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Rp. `+ kendo.toString(totalPPNTax, "n0") +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">Grand Total</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Rp. `+ kendo.toString(grandTotal, "n0") +`</label>
                        </div>
                    </div>
                    <div class="row" width="100%">
                        <div class="col-4">
                            <label class="form-label">DP</label>
                            <label class="form-label" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Rp. `+ kendo.toString(data.down_payment, "n0") +`</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        `;
        return html;
    }

    mergeItemArray(data){
        var itemDetail = this.editItemGridDataGenerator(data.purchase_order_detail);
        var itemBookingDetail = this.editItemGridDataGenerator(data.booking_order);
        
        var itemArray = [];
        for (let i = 0; i < itemDetail.length; i++){
            var str = { quantity: itemDetail[i].qty, 
                        uom: itemDetail[i].uom, 
                        base_quantity: itemDetail[i].real_qty,
                        product_code: itemDetail[i].product_code, 
                        item_name: itemDetail[i].item_name, 
                        vat_per_unit: itemDetail[i].net,
                        total_price: itemDetail[i].total_price,
                        type: 'Item' }
            if(itemDetail[i].qty > 0){
                itemArray.push(str);
            }
        }

        for (let i = 0; i < itemBookingDetail.length; i++){
            var str = { quantity: itemBookingDetail[i].qty, 
                        uom: itemBookingDetail[i].uom, 
                        base_quantity: itemBookingDetail[i].real_qty,
                        product_code: itemBookingDetail[i].product_code, 
                        item_name: itemBookingDetail[i].item_name, 
                        vat_per_unit: itemBookingDetail[i].net,
                        total_price: itemBookingDetail[i].total_price,
                        type: 'Item Booking' }
            if(itemBookingDetail[i].qty > 0){
                itemArray.push(str);
            }
        }

        return itemArray;
    }

    mergeItemDueDayArray(itemDetail, itemBookingDetail){
        var itemArray = [];
        for (let i = 0; i < itemDetail.length; i++){
            itemArray.push(itemDetail[i].type_detail_id);
        }

        for (let i = 0; i < itemBookingDetail.length; i++){
            itemArray.push(itemBookingDetail[i].type_detail_id);
        }

        return itemArray;
    }

    async getDueDate(customerId, projectId, typeDetailId){
        var variables = {
            customerId : customerId,
            projectId : projectId,
            typeDetailId : typeDetailId };

        var query = gql`query($customerId:Int, $projectId:Int, $typeDetailId:[String!]){
            GetTransactionDueDay(CustomerID:$customerId, ProjectID:$projectId, TypeDetailID:$typeDetailId)
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionDueDay == null ? 0 : result.data.GetTransactionDueDay;
    }

    itemPOPDF(itemDetail, itemBookingDetail){
        var arrayObject = [];

        if(itemDetail.length != 0){
            for (let i = 0; i < itemDetail.length; i++){
                var quantity_booking_order = 0;
                
                if(itemBookingDetail != null){
                    if(itemBookingDetail.length != 0){
                        var bookingItemData = itemBookingDetail.find(c => c.item_id == itemDetail[i].item_id);
                        
                        if(bookingItemData != undefined || bookingItemData != null){
                            quantity_booking_order = bookingItemData.quantity;
                        }
                    }
                };
                if(itemBookingDetail != null) {
                    if (itemBookingDetail.length != 0) {
                        var bookingItemData = itemBookingDetail.find(c => c.item_id == itemDetail[i].item_id);
    
                        if(bookingItemData != undefined || bookingItemData != null){
                            quantity_booking_order = bookingItemData.quantity;
                        }
                    }
                };

                var quantity = itemDetail[i].quantity + quantity_booking_order;
                
                var price_discount = itemDetail[i].discount1_amount + itemDetail[i].discount2_amount + itemDetail[i].discount3_amount + itemDetail[i].discount4_amount + itemDetail[i].discount5_amount + itemDetail[i].discount_cash_amount;
                var real_qty = itemDetail[i].conv_amount * quantity;
                var net = itemDetail[i].price_per_unit - price_discount;
                var total_net = quantity * net;

                var str = { 
                    qty_string : kendo.toString(quantity, "n0"),
                    qty : quantity,
                    base_qty : real_qty,
                    uom : itemDetail[i].uom,
                    base_uom : itemDetail[i].base_uom,
                    name : itemDetail[i].item_name + " @" + real_qty + " " + itemDetail[i].base_uom,
                    net : net,
                    total_net : total_net,
                    net_string : kendo.toString(net, "n0"),
                    total_net_string : kendo.toString(total_net, "n0")
                }

                if(itemDetail[i].quantity > 0){
                    arrayObject.push(str);
                }
            }
        }

        // if(itemBookingDetail != null) {
        //     if (itemBookingDetail.length != 0){
        //         for (let i = 0; i < itemBookingDetail.length; i++){
        //             var resultData = arrayObject.find(c => c.item_id == itemBookingDetail[i].item_id);
    
        //             if(resultData == undefined || resultData == null){
        //                 var quantity = itemBookingDetail[i].quantity;
                
        //                 var price_discount = itemBookingDetail[i].discount1_amount + itemBookingDetail[i].discount2_amount + itemBookingDetail[i].discount3_amount + itemBookingDetail[i].discount4_amount + itemBookingDetail[i].discount5_amount + itemBookingDetail[i].discount_cash_amount;
        //                 var real_qty = itemBookingDetail[i].conv_amount * quantity;
        //                 var net = itemBookingDetail[i].price_per_unit - price_discount;
        //                 var total_net = quantity * net;

        //                 var str = { qty : quantity,
        //                             base_qty : real_qty,
        //                             uom : itemBookingDetail[i].uom,
        //                             base_uom : itemBookingDetail[i].base_uom,
        //                             name : itemBookingDetail[i].item_name + " @" + real_qty + " " + itemBookingDetail[i].base_uom,
        //                             net : net,
        //                             total_net: total_net,
        //                             net_string : kendo.toString(net, "n0"),
        //                             total_net_string : kendo.toString(total_net, "n0")}

        //                 arrayObject.push(str);
        //             }
        //         }
        //     }
        // }
        
        return arrayObject;
    }

    transactionPurchaseOrderDetail(itemDetail, itemBookingDetail, tax){
        var result = [];
        
        if(itemDetail.length != 0){
            for (let i = 0; i < itemDetail.length; i++){
                var bookingItemData = itemBookingDetail.find(c => c.item_id == itemDetail[i].item_id);
                var quantityBookingOrder = 0;

                if(bookingItemData != undefined || bookingItemData != null){
                    quantityBookingOrder = bookingItemData.qty;
                };

                var taxAmount = itemDetail[i].net * (tax / 100);
                var vatPerUnit = itemDetail[i].net + taxAmount;
                var sumQuantity = itemDetail[i].qty;

                var str = { 
                    item_id: itemDetail[i].item_id,
                    uom: itemDetail[i].uom,
                    price_per_unit: itemDetail[i].price,
                    price: itemDetail[i].price * sumQuantity,
                    price_discount_per_unit: itemDetail[i].net,
                    price_discount: itemDetail[i].net * sumQuantity,
                    vat_per_unit: vatPerUnit ,
                    vat: vatPerUnit * sumQuantity,
                    quantity: itemDetail[i].qty,
                    quantity_booking_order: quantityBookingOrder,
                    discount1: itemDetail[i].discount1,
                    discount2: itemDetail[i].discount2,
                    discount3: itemDetail[i].discount3,
                    discount4: itemDetail[i].discount4,
                    discount5: itemDetail[i].discount5,
                    discount_cash: itemDetail[i].discount_cash,
                    discount1_amount: itemDetail[i].discount_amount_1,
                    discount2_amount: itemDetail[i].discount_amount_2,
                    discount3_amount: itemDetail[i].discount_amount_3,
                    discount4_amount: itemDetail[i].discount_amount_4,
                    discount5_amount: itemDetail[i].discount_amount_5,
                    discount_cash_amount: itemDetail[i].discount_amount_cash,
                    tax_amount: taxAmount
                };
                result.push(str);
            }
        }
        if (itemBookingDetail.length != 0){
            for (let i = 0; i < itemBookingDetail.length; i++){
                var resultData = result.find(c => c.item_id == itemBookingDetail[i].item_id);
                
                if(resultData == undefined || resultData == null){
                    var taxAmount = itemBookingDetail[i].net * (tax / 100);
                    var vatPerUnit = itemBookingDetail[i].net + taxAmount;
                    
                    var str = { 
                        item_id: itemBookingDetail[i].item_id,
                        uom: itemBookingDetail[i].uom,
                        price_per_unit: itemBookingDetail[i].price,
                        price: 0,
                        price_discount_per_unit: itemBookingDetail[i].net,
                        price_discount: 0,
                        vat_per_unit: vatPerUnit,
                        vat: 0,
                        quantity: 0,
                        quantity_booking_order: itemBookingDetail[i].qty,
                        discount1: itemBookingDetail[i].discount1,
                        discount2: itemBookingDetail[i].discount2,
                        discount3: itemBookingDetail[i].discount3,
                        discount4: itemBookingDetail[i].discount4,
                        discount5: itemBookingDetail[i].discount5,
                        discount_cash: itemBookingDetail[i].discount_cash,
                        discount1_amount: itemBookingDetail[i].discount_amount_1,
                        discount2_amount: itemBookingDetail[i].discount_amount_2,
                        discount3_amount: itemBookingDetail[i].discount_amount_3,
                        discount4_amount: itemBookingDetail[i].discount_amount_4,
                        discount5_amount: itemBookingDetail[i].discount_amount_5,
                        discount_cash_amount: itemBookingDetail[i].discount_amount_cash,
                        tax_amount: taxAmount
                    };
                    result.push(str);
                }
            }
        }

        return result;
    }

    async getDropdownPPNTax(){
        var query = gql`query{
            GetMasterTax{
                percentage
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetMasterTax != null){
            for (let i = 0; i < result.data.GetMasterTax.length; i++) {
                var str = { value:result.data.GetMasterTax[i].percentage, 
                            label:result.data.GetMasterTax[i].percentage}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    countStockReEdit(selectedItem, reEditData){
        var result = 0;

        if(reEditData != null){
            if(reEditData.length != 0){
                var data = reEditData.find(c => c.item_id == selectedItem.item_id);
                
                if(data == undefined || data == null){
                    result = 0;
                }else{
                    result = (data.qty * data.conv_amount) / selectedItem.conv_amount;
                }
            }
        };

        return Math.floor(result);
    }

    itemPIPDF(itemDetail){
        var arrayObject = [];
        if(itemDetail.length != 0){
            for (let i = 0; i < itemDetail.length; i++){
                if(itemDetail[i].quantity > 0){
                    var price_discount = itemDetail[i].discount1_amount + itemDetail[i].discount2_amount + itemDetail[i].discount3_amount + itemDetail[i].discount4_amount + itemDetail[i].discount5_amount + itemDetail[i].discount_cash_amount;
                    var net = itemDetail[i].price_per_unit - price_discount;
                    var total_net = itemDetail[i].quantity * net;
                    var real_quantity = itemDetail[i].quantity * itemDetail[i].conv_amount

                    var str = { 
                        quantity_string : kendo.toString(itemDetail[i].quantity, "n0"),
                        quantity : itemDetail[i].quantity,
                        uom : itemDetail[i].uom,
                        name : itemDetail[i].item_name + " @" + real_quantity + " " + itemDetail[i].base_uom,
                        net : net,
                        total_net : total_net,
                        net_string : kendo.toString(net, "n0"),
                        total_net_string : kendo.toString(total_net, "n0"),
                        tax_amount : itemDetail[i].tax_amount
                    }
                                
                    arrayObject.push(str);
                }
            }
        }

        return arrayObject;
    }
}

export default new PurchaseOrderService();